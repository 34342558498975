<script setup lang="ts">
// Components
import actionListCard from '../components/cards/actionListCard.vue';

// Stores
const action = actionStore();

// Data
const { loading, error } = useCustomAsyncData({
  promise: action.loadActions(),
});

// Computed
const getSavingCost = computed(() => {
  return action.getCloseActions.reduce((acc, curr) => {
    if (curr.week_impact_cost) {
      return acc + curr.week_impact_cost;
    }
    return acc;
  }, 0);
});
</script>
<template>
  <div class="flex flex-col w-full h-full bg-gray-50">
    <ui-header :title="$t('navigation.action_plan')" />
    <div class="flex justify-start flex-col gap-4 p-4 overflow-y-auto">
      <!-- Stats -->
      <div class="grid gap-4 grid-cols-3">
        <ui-stat-card
          :loading="loading"
          :title="$t('action_plan.rates_of_progress')"
          type="progress"
          :data="{
            value: action.getCloseActions.length,
            total: action.getActiveActions.length + action.getCloseActions.length,
          }"
        />
        <ui-stat-card :loading="loading" :title="$t('action_plan.saving_already_made')" type="euro" :data="getSavingCost" />
        <ui-stat-card :loading="loading" :title="$t('action_plan.potential_saving')" type="euro" :data="action.getPotentialSaving" />
      </div>
      <!-- Card -->
      <action-list-card :loading="loading" :error="error" />
    </div>
  </div>
</template>
